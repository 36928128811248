


































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import { apiGoodsDetail, getLongRangeGoodsMcsSourceEnum } from "@/api/goods";

@Component({
    components: { LsDialog },
})
export default class DetailsPage extends Vue {
    activeName = ["1", "2"];
    viewDetailsInfo: any = {
        goods_data: {
            pc_body: "",
            mobile_body: "",
        },
    };
    mcsSourceEnum: any = [];
    loading: boolean = false;
    //获取详情
    async longRangeGoodsDetail(id: any) {
        this.loading = true;
        const res = await apiGoodsDetail(id);
        if (res) this.viewDetailsInfo = res;
        this.loading = false;
    }

    back() {
        this.$router.push({
            path: "/goods/goods_center/goods",
            query: {
                t: '2',
                page: this.$route.query.page || '1',
                size: this.$route.query.size || '10',
            },
        });
    }
    async created() {
        const id = this.$route.query.id || null;
        this.longRangeGoodsDetail(id);
    }
}
